import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/app/globals.css");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/app/supabase-provider.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Accordion/Accordion.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Accordion/AccordionContent.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Accordion/AccordionPanel.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Accordion/AccordionTitle.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Banner/BannerCollapseButton.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Carousel/Carousel.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/DarkThemeToggle/DarkThemeToggle.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Datepicker/Datepicker.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Dropdown/Dropdown.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Dropdown/DropdownDivider.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Dropdown/DropdownHeader.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Dropdown/DropdownItem.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Floating/Floating.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Modal/Modal.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Modal/ModalBody.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Modal/ModalFooter.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Modal/ModalHeader.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Navbar/Navbar.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Navbar/NavbarBrand.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Navbar/NavbarCollapse.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Navbar/NavbarLink.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Navbar/NavbarToggle.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Rating/Rating.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Rating/RatingStar.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Sidebar/Sidebar.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Sidebar/SidebarCollapse.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Sidebar/SidebarCTA.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Sidebar/SidebarItem.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Sidebar/SidebarItemGroup.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Sidebar/SidebarItems.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Sidebar/SidebarLogo.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Table/Table.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Table/TableBody.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Table/TableCell.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Table/TableHead.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Table/TableHeadCell.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Table/TableRow.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Tabs/TabItem.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Tabs/Tabs.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Timeline/Timeline.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Timeline/TimelineBody.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Timeline/TimelineContent.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Timeline/TimelineItem.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Timeline/TimelinePoint.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Timeline/TimelineTime.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Timeline/TimelineTitle.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Toast/Toast.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/components/Toast/ToastToggle.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/hooks/use-theme-mode.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/theme-store/init/client.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/flowbite-react/lib/esm/theme-store/init/mode.js");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/next/font/local/target.css?{\"path\":\"node_modules/geist/dist/sans.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/geist-sans/Geist-Variable.woff2\",\"variable\":\"--font-geist-sans\",\"weight\":\"100 900\"}],\"variableName\":\"GeistSans\"}");
import(/* webpackMode: "eager" */ "/home/runner/work/buildmind-watcher-ai/buildmind-watcher-ai/node_modules/nextjs-toploader/dist/index.js")